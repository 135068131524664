import { Link, LinkProps } from "@biblioteksentralen/js-utils";
import NextLink from "next/link";
import React, { AnchorHTMLAttributes } from "react";

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement>, Omit<LinkProps, "color"> {
  href: string;
}

function InternalLink(props: Props) {
  const { href, ...rest } = props;
  return (
    <NextLink href={href} passHref>
      <Link {...rest} />
    </NextLink>
  );
}

export default InternalLink;
